@font-face {
  font-family: "certificate";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("certificate"), url(../public/assets/fonts/certificate.ttf) format("truetype");
}

/* .list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
} */